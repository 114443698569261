<template>
  <div class="page">
    <div class="content">
      <el-form :model="ruleForm" ref="ruleForm" :rules="rules" label-width="150px" class="demo-ruleForm">
        <el-form-item label="是否需要社区审核：" prop="communityOption">
          <el-radio-group v-model="ruleForm.communityOption">
            <el-radio :label="0">需要</el-radio>
            <el-radio :label="1">不需要</el-radio>
          </el-radio-group>
        </el-form-item>
        <el-form-item label="是否需要街道审核：" prop="tenantOption">
          <el-radio-group v-model="ruleForm.tenantOption">
            <el-radio :label="0">需要</el-radio>
            <el-radio :label="1">不需要</el-radio>
          </el-radio-group>
        </el-form-item>
        <el-form-item label="是否需要民政审核：" prop="civilAdministrationOption">
          <el-radio-group v-model="ruleForm.civilAdministrationOption">
            <el-radio :label="0">需要</el-radio>
            <el-radio :label="1">不需要</el-radio>
          </el-radio-group>
        </el-form-item>
        <el-form-item label="截止提交日期：" prop="resource">
          <div style="display: flex;">
            <el-select style="width: 100px" v-model="ruleForm.closingDate" disabled>
              <el-option label="次月" value="次月"></el-option>
            </el-select>
            <el-input style="width: 150px;margin-left: 15px" disabled v-model="ruleForm.closingDateDay"></el-input>
          </div>
        </el-form-item>
        <el-form-item label="床位补贴标准：" prop="resource">
          <el-table
            :data="ruleForm.bedAllowance"
            style="width:80%"
          >
            <el-table-column
              prop="elderlyType"
              align="center"
              label="老人类型">
              <template v-slot="scope">
                <span>{{ ['本区老人', '非本区老人'][scope.row.elderlyType] }}</span>
              </template>
            </el-table-column>
            <el-table-column align="center"  label="补贴金额（元/月）">
              <el-table-column
                prop="institutionalSubsidies"
                align="center"
                label="机构补贴部分">
              </el-table-column>
              <el-table-column
                prop="elderlySubsidies"
                align="center"
                label="老人补贴部分">
              </el-table-column>
            </el-table-column>
          </el-table>
        </el-form-item>
        <el-form-item label="说明：" prop="resource">
          <div>
            1、机构提交审核后，若不存在对应的审核单位，则会跳过该级审核节点，直接到下级审核节点；
          </div>
          <div>
            2、截止提交日期：养老机构每月提交相关审核资料的截止时间；
          </div>
          <div>
            3、床位补贴标准：指的是符合服务补贴条件的情况下，每个床位每个月可获得的补贴金额。
          </div>
        </el-form-item>
        <el-form-item>
          <el-button style="margin-left: 40%;margin-top: 30px" type="primary" @click="submitForm">保存</el-button>
        </el-form-item>
      </el-form>
    </div>
  </div>
</template>

<script>
  import { basedatadetails, save } from "@/api/institutionalSubsidies"
  export default {
    name: "basicData",
    data() {
      return {
        ruleForm: {
          id: '',
          communityOption: '',
          tenantOption: '',
          civilAdministrationOption: '',
          closingDate: '次月',
          closingDateDay: '10号',
          bedAllowance: [{
            elderlyType: 0,
            institutionalSubsidies: '100',
            elderlySubsidies: '200'
          },
            {
              elderlyType: 1,
              institutionalSubsidies: '0',
              elderlySubsidies: '0'
            }]
        },
        rules: {
          communityOption: [
            {required: true, message: '请选择是否需要社区审核', trigger: 'change'}
          ],
          tenantOption: [
            {required: true, message: '请选择是否需要街道审核', trigger: 'change'}
          ],
          civilAdministrationOption: [
            {required: true, message: '请选择是否需要民政审核', trigger: 'change'}
          ]
        }
      }
    },
    mounted() {
      this.basedatadetailsData()

    },
    methods: {
      async basedatadetailsData() {
        const res = await basedatadetails()
        let { data } = res
          this.ruleForm.id = data.id
          this.ruleForm.communityOption = data.communityOption
          this.ruleForm.tenantOption = data.tenantOption
          this.ruleForm.civilAdministrationOption = data.civilAdministrationOption
      },
      submitForm() {
        this.$refs['ruleForm'].validate((valid) => {
          if (valid) {
            let { communityOption, tenantOption, civilAdministrationOption} = this.ruleForm
            if(communityOption == 1 & tenantOption == 1 & civilAdministrationOption == 1) return this.$message.error('审核流程至少需要一个审核节点！')
            this.saveData()
          } else {
            console.log('error submit!!');
            return false;
          }
        });
      },
      async saveData() {
        let params = JSON.parse(JSON.stringify(this.ruleForm))
        if(!params.id) Reflect.deleteProperty(params, 'id')
        const res = await save(params)
        this.$message.success(res.data)
      }
    }
  }
</script>

<style lang="scss" scoped>
  .page {
    /*width: 100%;*/
    height: calc(100vh - 210px);
    overflow: hidden;
    background-color: #ffffff;
    padding: 30px 40px;
    /*box-sizing: border-box;*/
    /*margin-right: 20px;*/
    .content {
      /*min-height: 600px;*/
      height: 100%;
      overflow-y: auto;
    }
    .beforeStyle {
      position: relative;
    }
    .beforeStyle:before {
      content: "*";
      color: red;
      position: absolute;
      left: -6px;
      top: 5px;
    }
  }

</style>
